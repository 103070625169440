.skills-box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    column-gap: 3rem;
    row-gap: 4rem;
    justify-content: center;
}

.skill{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skill-icon{
    font-size: 4rem;
    transition: transform 0.7s ease-in-out;
}

.skill-icon img{
    height: 2.5rem;
}

.skill-icon:hover {
    animation: bounce 0.5s ease-in-out;
}

.logo-icon{
    transition: transform 0.7s ease-in-out;
}

.logo-icon:hover{
    animation: bounce 0.5s ease-in-out;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0);
    }
}