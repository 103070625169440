.resume-box {
    background: #ffffff;
    box-shadow: 0 0 1.25rem rgba(31, 45, 61, 0.08);
    border-radius: 10px;
  }
  .resume-header{
    padding: 10px 0 0 10px;
  }
  .skills-used{
    padding-top: 10px;
    font-size: small;
  }
  .resume-box ul {
    margin: 0;
    padding: 30px 20px;
    list-style: none;
  }
  .resume-box li {
    position: relative;
    padding: 0 20px 0 60px;
    margin: 0 0 30px;
  }
  .resume-box li:last-child {
    margin-bottom: 0;
  }
  .resume-box li:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 20px;
    border-left: 1px dashed #fc5356;
    bottom: 0;
  }
  .resume-box .icon {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 0;
    right: 0;
    color: #fc5356;
    line-height: 40px;
    background: #ffffff;
    text-align: center;
    z-index: 1;
    border: 1px dashed;
    border-radius: 50%;
  }
  .resume-box .time {
    background: #fc5356;
    color: #ffffff;
    font-size: 10px;
    padding: 2px 10px;
    display: inline-block;
    margin-bottom: 12px;
    border-radius: 20px;
    font-weight: 600;
  }
  .resume-box h5 {
    font-weight: 700;
    color: black;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .resume-box p {
    margin: 0;
  }
  
  .resume-box li:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 20px;
      border-left: 1px dashed #fc5356;
      bottom: 0;
  }