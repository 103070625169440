.project-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    background-color: #FAFAFA;
    border-radius: 5%;
}

.project-content{
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
}

.project-content strong{
    font-weight:900;
    font-size: large;
}

.content-text{
    color:#65656d
}